<template>
  <div class="addressDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form
        ref="dialogForm"
        :rules="rules"
        :model="form"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="麻将桌号" prop="mahjongNumber">
              <el-input
                v-model="form.mahjongNumber"
                placeholder="请输入麻将桌号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属地址" prop="belong">
              <el-select
                v-model="form.belong"
                placeholder="请选择归属地址"
                style="width: 100%"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="item in mahjongList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="返积分（分）" prop="jifen">
              <el-input
                v-model="form.jifen"
                placeholder="请输入返积分"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "新增麻将桌",
      mahjongList: [
        {
          label: "成都南站菜秘书麻将馆",
          value: "1",
        },
      ],
      form: {
        mahjongNumber: "",
        belong: "1",
        jifen:'100'
      },
      rules: {},
    };
  },
  methods: {
    submitData() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          let params = { ...this.form };
          let url = "";
          if (params.id) {
            //修改
            url = "api/mahjong/update";
          } else {
            url = "api/mahjong/add";
          }

          this.getAjax(url, params, "post").then(() => {
            this.$message.success("操作成功");
            this.closeEvent();
            this.$emit("search");
          });
        }
      });
    },
    closeEvent() {
      this.visible = false;
      this.dialogTitle = "新增麻将桌";
      this.$refs.dialogForm.resetFields();
      this.form = {};
    },
    getInfo(item) {
      this.dialogTitle = "编辑麻将桌";
      this.form = JSON.parse(JSON.stringify(item));
      this.visible = true;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
