var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"userList"},[_c('searchBar',{attrs:{"formItems":_vm.formItems},on:{"search":_vm.search}}),(_vm.power === '1')?_c('div',{staticStyle:{"margin-bottom":"10px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.add()}}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v("新增 ")])],1):_vm._e(),_c('div',{staticClass:"tableBox"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"cell-style":{ textAlign: 'center' },"header-cell-style":{ textAlign: 'center' },"border":""}},[_c('el-table-column',{attrs:{"prop":"mahjongNumber","label":"麻将桌号","textAlign":"left","width":"150"}}),_c('el-table-column',{attrs:{"prop":"detail","label":"二维码"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('vue-qr',{ref:"qrCode",attrs:{"text":scope.row.id + '',"color-dark":"#000","width":"100","height":"100"}})]}}])}),_c('el-table-column',{attrs:{"prop":"nickName","label":"麻将馆名称","textAlign":"left"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.mahjongPosition(scope.row.belong))+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"upPosition","label":"上位","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.upUserInfo)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"50px","height":"50px","border-radius":"100%"},attrs:{"src":scope.row.upUserInfo
                  ? scope.row.upUserInfo.headUrl
                  : require('@/assets/images/1.png')}}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(scope.row.upUserInfo ? scope.row.upUserInfo.nickName : ""))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"downPosition","label":"下位"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.downUserInfo)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"50px","height":"50px","border-radius":"100%"},attrs:{"src":scope.row.downUserInfo
                  ? scope.row.downUserInfo.headUrl
                  : require('@/assets/images/1.png')}}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(scope.row.downUserInfo ? scope.row.downUserInfo.nickName : ""))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"leftPosition","label":"左位"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.leftUserInfo)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"50px","height":"50px","border-radius":"100%"},attrs:{"src":scope.row.leftUserInfo
                  ? scope.row.leftUserInfo.headUrl
                  : require('@/assets/images/1.png')}}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(scope.row.leftUserInfo ? scope.row.leftUserInfo.nickName : ""))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"rightPosition","label":"右位"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.rightUserInfo)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('img',{staticStyle:{"width":"50px","height":"50px","border-radius":"100%"},attrs:{"src":scope.row.rightUserInfo
                  ? scope.row.rightUserInfo.headUrl
                  : require('@/assets/images/1.png')}}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v(_vm._s(scope.row.rightUserInfo ? scope.row.rightUserInfo.nickName : ""))])]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"detail","label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{class:scope.row.status === '0'
                ? 'c_grey'
                : scope.row.status === '1'
                ? 'c_green'
                : ''},[_vm._v(" "+_vm._s(scope.row.status === "0" ? "空闲" : scope.row.status === "1" ? "进行中" : "")+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"jifen","label":"返积分","textAlign":"left","width":"150"}}),_c('el-table-column',{attrs:{"prop":"","label":"操作","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.emptyTable(scope.row)}}},[_vm._v("清台")]),(scope.row.status === '0')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.beginTable(scope.row)}}},[_vm._v("开始")]):_vm._e(),(scope.row.status === '1')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.endTable(scope.row)}}},[_vm._v("结算")]):_vm._e(),(_vm.power === '1')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.editData(scope.row)}}},[_vm._v("编辑")]):_vm._e(),(_vm.power === '1')?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.deleteData(scope.row)}}},[_vm._v("删除")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"fenye"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.page.page,"page-sizes":[5, 10, 20, 30, 50],"page-size":_vm.page.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('ActivityDialog',{ref:"dialog",on:{"search":_vm.initPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }